/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getScrollTriggers, setScrollTrigger} from "utils/scrolltriggers";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components), {ResourcePanel, Pin, InfoNugget, InfoItem, Headline, Obl, CareInstructions, CareItem} = _components;
  if (!CareInstructions) _missingMdxReference("CareInstructions", true);
  if (!CareItem) _missingMdxReference("CareItem", true);
  if (!Headline) _missingMdxReference("Headline", true);
  if (!InfoItem) _missingMdxReference("InfoItem", true);
  if (!InfoNugget) _missingMdxReference("InfoNugget", true);
  if (!Obl) _missingMdxReference("Obl", true);
  if (!Pin) _missingMdxReference("Pin", true);
  if (!ResourcePanel) _missingMdxReference("ResourcePanel", true);
  return React.createElement(React.Fragment, null, React.createElement(ResourcePanel, {
    id: "figure01",
    image: "figure01",
    imageAlt: "lorem ipsum",
    roundTopCorners: true,
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure01",
    type: "start",
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Safe Sunscreens"), React.createElement(_components.p, null, "Despite the natural SPF protection from your complexion and the barrier of your clothes, you still need sunscreen to protect against UVA rays. We recommend:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "A minimum of SPF 30"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "SPF 50 if you're outside for more than an hour"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, {
    noBorder: true
  }, "Reapply sunscreen every two hours — more frequently if you're sweating or swimming as it rinses off and loses effectiveness"), "\n"), "\n")), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Apply It"), React.createElement(_components.p, null, "Though melanin provides extra protection from the sun's rays, ", React.createElement(_components.strong, null, "people of all skin tones should  apply suscreen.")), React.createElement(_components.p, null, "There are many options, and it is important to test out different types to find out which works best for you.")), React.createElement(Pin, {
    id: "figure01",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure02",
    image: "figure02",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure02",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Chemical"), React.createElement(_components.p, null, "Chemical sunscreen ", React.createElement(_components.strong, null, "acts like a sponge"), ", and absorbs most of the harmful rays, keeping it from damaging the skin.")), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "These sunscreens contain ingredients like"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "oxybenzone"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "avobenzone"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "octisalate"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "homosalate"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "octocrylene"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "octinoxate"), "\n"), "\n"), React.createElement(_components.p, null, "Though these leave less white residue, they linger in the body for longer, which some may want to avoid.")), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Though some UV rays still reach the skin, most rays are absorbed into the sunscreen.")), React.createElement(Pin, {
    id: "figure02",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure03",
    image: "figure03",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure03",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Physical"), React.createElement(_components.p, null, "Physical sunscreen ", React.createElement(_components.strong, null, "acts like a shield"), ", causing harmful sun rays to bounce off of the skin.")), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "These sunscreens contain minerals like"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "titanium"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "dioxide"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "zinc oxide"), "\n"), "\n"), React.createElement(_components.p, null, "Though these ingredients are considered safer, they may leave a white residue.")), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Though some UV rays still hit the skin, most rays are reflected by the sunscreen.")), React.createElement(Pin, {
    id: "figure03",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "video01",
    video: "603A8hGZdWA",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "video01",
    type: "start",
    height: 300,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Hear Dr. Chesahna Kindred of the ", React.createElement(Obl, {
    to: "https://kindredhairandskin.com/",
    title: "Dr. Kindred's Practice"
  }, "Kindred Hair and Skin Center"), " share more on what to look for in a sunscreen.")), React.createElement(Pin, {
    id: "video01",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(CareInstructions, null, React.createElement(Headline, {
    level: 3
  }, "Be Informed"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Potentially harmful ingredients are limited to chemical sunscreens"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Mineral sunscreens, containing ", React.createElement(Obl, {
    to: "https://amzn.to/3AE6OeP",
    title: "Amazon Affiliate Link"
  }, "zinc oxide and/or titanium dioxide"), ", are designated by the FDA as GRASE (\"generally regarded as safe and effective\")."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "The reputable Consumers Reports suggest chemical sunscreens without oxybenzone to protect against UV radiation and sunburn."), "\n"), "\n"), React.createElement(Pin), React.createElement(Headline, {
    level: 4
  }, "Online Sunscreen Resources"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(Obl, {
    to: "https://www.nbcnews.com/select/news/sunscreen-dark-skin-tones-ncna1237144#anchor-WhatSPFisbestfordarkskin",
    title: "NBC News Article"
  }, "Sunscreen for dark skin tones")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(Obl, {
    to: "https://www.healthline.com/health/beauty-skin-care/best-sunscreens-for-dark-skin",
    title: "Healthline Article"
  }, "Healthline's Sunscreen picks")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(Obl, {
    to: "https://www.harpersbazaar.com/beauty/skin-care/g33474309/best-sunscreen-for-black-people/",
    title: "Harper's Bazaar Article"
  }, "Harper's Bazaar")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(Obl, {
    to: "https://www.oprahdaily.com/beauty/skin-makeup/g26719162/best-sunscreen-for-dark-skin-tones/",
    title: "Oprah Daily Article"
  }, "Oprah Daily")), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
